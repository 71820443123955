<template>
  <div :is="bankData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="bankData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.banks.bankNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-bank-list' }">
          {{ $t("message.banks.bankList") }}
        </b-link>
        {{ $t("message.banks.forOtherBanks") }}
      </div>
    </b-alert>

    <template v-if="bankData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.banks.viewEdit") }}</h2>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.name") }}
                </template>
                <b-form-input v-model="bankData.name" disabled />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankName") }}
                </template>
                <b-form-input v-model="bankData.bankName" disabled />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankNumber") }}
                </template>
                <b-form-input
                  v-model="bankData.bankNumber"
                  type="number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankAgency") }}
                </template>
                <b-form-input
                  v-model="bankData.bankAgency"
                  type="number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankAccount") }}
                </template>
                <b-form-input
                  v-model="bankData.bankAccount"
                  type="number"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_bank')"
                  :to="{ name: 'apps-banks-edit', params: { id: bankData.id } }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-if="restrictions('button_delete_bank')"
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-bank-list' }"
            @click="deleteBank()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BTab,
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      userData: store.state.user.userData,
      typeOptions: [
        { label: "Isolado", value: "isolate" },
        { label: "Recorrente", value: "recurrent" },
      ],
    };
  },

  setup() {
    const bankData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}bank/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        bankData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          bankData.value = undefined;
        }
      });

    return {
      bankData,
    };
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    deleteBank() {
      axios({
        method: "delete",
        url: `${URL_API}bank/${this.bankData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.deleted"),
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            bankData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.mtn {
  margin-top: -0.8rem;
}
</style>
